<template>
  <div>
    <color-picker :selected.sync="colorValue" />
  </div>
</template>
<script>
export default {
  components: {
    ColorPicker: () => import('@/components/color-picker')
  },
  data () {
    return {
      colorValue: 'red'
    }
  }
}
</script>
